import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ActionButtonEvent } from '../action-button-event';
import { ActionButtonsService } from '../action-buttons.service';

@Component({
  selector: 'itl-alberta-page-header-menu',
  templateUrl: './alberta-page-header-menu.component.html',
  styleUrls: ['./alberta-page-header-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlbertaPageHeaderMenuComponent implements AfterViewInit, OnDestroy {
  @ViewChild('actionButtons', { read: ViewContainerRef, static: true }) actionButtons: ViewContainerRef;
  private _ngUnsubscribe = new Subject();

  constructor(
    private _actionButtonService: ActionButtonsService<ActionButtonEvent>,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this._actionButtonService.events.pipe(takeUntil(this._ngUnsubscribe)).subscribe(event => {
      this.actionButtons.clear();
      if (event && event.factory) {
        const buttons = this.actionButtons.createComponent(event.factory);
        buttons.instance.options = event.options;
        this._changeDetectorRef.markForCheck();
      }
    });
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next(null);
    this._ngUnsubscribe.complete();
  }
}
