import { IModel } from '@alberta/konexi-shared';
import { Paginated } from 'src/app/shared/services/contracts/database/paginated';
import { IQuery } from 'src/app/shared/services/contracts/query/query';

import { IDbQueryStrategy } from '../contracts/repository/db-query-strategy';
import { RawOptions } from '../contracts/repository/raw-options';
import { IRepository } from '../contracts/repository/repository';

export class Repository<T extends IModel & {}> implements IRepository<T> {
  constructor(private _dbQueryStrategy: IDbQueryStrategy<T>) {}

  get ready(): Promise<void> {
    return this._dbQueryStrategy.ready;
  }

  async count(): Promise<number> {
    return this._dbQueryStrategy.storage.length();
  }

  async createOrUpdateFromSync(itemsForDb: { items: T[]; deletable: boolean }, batch: any[]): Promise<any> {
    await this.ready;

    await this._dbQueryStrategy.storage.setItems(itemsForDb, batch);
  }

  execBatch(batch: any[]): Promise<any> {
    return;
  }

  public async addOrUpdateIndex(item: T, respectReady: boolean = true): Promise<any> {
    return true;
  }

  async createOrUpdate(item: T): Promise<any> {
    item.timestamp = new Date();
    return this.createOrUpdateInternal(item);
  }

  private async createOrUpdateInternal(item: T): Promise<any> {
    await this.ready;

    return this._dbQueryStrategy.storage.setItem(item._id, item);
  }

  async getAll(): Promise<T[]> {
    return this._dbQueryStrategy.getAll();
  }

  getPaginated(pageSize: number, offset: number): Promise<Paginated> {
    throw new Error('Method not implemented.');
  }

  async getItems(keys: string[]): Promise<any[]> {
    return this._dbQueryStrategy.getItems(keys);
  }

  async get(id: string): Promise<T> {
    return this._dbQueryStrategy.get(id);
  }

  delete(id: string): Promise<void> {
    return;
  }

  async search(query: IQuery): Promise<T[]> {
    return this._dbQueryStrategy.search(query);
  }

  async raw({
    aggregation,
    query,
    options,
  }: {
    sql: string;
    aggregation?: any[];
    query?: any;
    options?: RawOptions;
  }): Promise<any> {
    return this._dbQueryStrategy.raw({ aggregation, query, options });
  }
}
