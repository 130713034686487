<ion-list>
  <div class="header">
    <span class="header__text bold">{{ header }}</span>
  </div>
  <ion-item
    *ngFor="let sortItem of this.sortItems; last as isLast"
    (click)="toggleSelection(sortItem)"
    class="toggle-list-button"
    [ngClass]="{
      'toggle-list-button--blue': this.selectedSortItem === sortItem
    }"
    [button]="true"
    [lines]="isLast ? 'none' : 'full'"
  >
    <itl-alberta-icon
      [ngClass]="{
        'toggle-list-button--blue': this.selectedSortItem === sortItem
      }"
      [name]="sortItem.icon"
      class="toggle-list-icon"
    ></itl-alberta-icon>
    {{ sortItem.value }}
  </ion-item>
</ion-list>
