import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { SortSelectionItem } from '../../models/sort-selection-item';

@Component({
  selector: 'itl-sort-selection-popover',
  templateUrl: './sort-selection-popover.component.html',
  styleUrls: ['./sort-selection-popover.component.scss'],
})
export class SortSelectionPopoverComponent {
  @Input()
  header: string;

  @Input()
  sortItems: SortSelectionItem[];

  @Input()
  selectedSortItem: SortSelectionItem;

  constructor(private _popoverController: PopoverController) {}

  public async toggleSelection(sortItem: SortSelectionItem) {
    await this._popoverController.dismiss(sortItem);
  }
}
