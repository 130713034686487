import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ChatAssistantComponent } from 'src/app/src/app/chat-assistant/chat-assistant.component';
import { DirectivesModule } from '../../directives/directives.module';
import { AlbertaIconModule } from '../alberta-icon/alberta-icon.module';
import { SplitPaneComponent } from './split-pane.component';

@NgModule({
  declarations: [SplitPaneComponent],
  imports: [CommonModule, IonicModule, DirectivesModule, AlbertaIconModule, ChatAssistantComponent],
  exports: [SplitPaneComponent],
})
export class SplitPaneModule {}
