import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { IonRouterOutlet, NavController } from '@ionic/angular';

@Component({
  selector: 'itl-alberta-page',
  templateUrl: './alberta-page.component.html',
  styleUrls: ['./alberta-page.component.scss'],
})
export class AlbertaPageComponent {
  @ContentChild('footer', { static: true }) footer: TemplateRef<any>;
  @Input() changedBackRoute = null;
  @Input() header: string;
  /**
   * useInsets should be set to `false` if the safe-area-inset CSS properties should not be set for this page.
   * Results in the page stretching full width behind notches etc.
   * @type {boolean}
   */
  @Input() useInsets: boolean = true;
  @Input() secondHeader: string;
  @Output() actionMenuButtonPressed: EventEmitter<void> = new EventEmitter<void>();

  constructor(private _navCtrl: NavController, private _routerOutlet: IonRouterOutlet) {}

  async goBack() {
    if (this.changedBackRoute) {
      await this._navCtrl.navigateBack(this.changedBackRoute);
      return;
    }
    if (this._routerOutlet && this._routerOutlet.canGoBack()) {
      await this._routerOutlet.pop();
      return;
    }
    await this._navCtrl.navigateRoot(``);
  }

  menuAction() {
    this.actionMenuButtonPressed.emit();
  }
}
