import { IKnowledgeBaseConfig } from '@alberta/konexi-shared/dist/general/config/knowledge-base-config';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { AuthActions, AuthObserver, IAuthAction, AuthService as MobileOAuthService } from 'ionic-appauth';
import { clamp } from 'lodash';
import { takeWhile } from 'rxjs/operators';
import { IAuthEventData } from 'src/app/common/contracts/authentication/auth-event-data';
import { MenuItem } from 'src/app/common/menu/menu-item';
import { UPDATES } from 'src/app/help/updates';
import { EnvironmentService } from 'src/app/shared/services/environment/environment.service';

import { AuthService } from '../../services/auth.service';
import { ChatMessagesStatusService } from '../../services/chat/chat-messages-status.service';
import { AttachmentNotificationFilterService } from '../../services/notification-filter/attachment-notification-filter-service';
import { OrderNotificationFilterService } from '../../services/notification-filter/order-notification-filter-service';
import { TenantConfigService } from '../../services/tenant-config/tenant-config.service';
import { XmasService } from '../../services/xmas.service';
import { MainMenu } from './main-menu';

@Component({
  selector: 'itl-split-pane',
  templateUrl: './split-pane.component.html',
  styleUrls: ['./split-pane.component.scss'],
})
export class SplitPaneComponent implements OnInit {
  public aiAssitantPanelIsOpen = false;

  public menuItems: MenuItem[];
  public isMasterBranch: boolean;
  public branchName: string;
  public version: string;
  public showHelpPageDisturber = false;
  public showNewNotifications = false;
  public isMenuExpanded = false;
  public selectedCategory = -1;
  public userIsAuthenticated = false;
  public unreadMessages = 0;
  public unreadAgentMessages = 0;
  public numberOfNewNotifications = 0;
  public userIsChatAgent = false;
  public userHasAppointmentRights = false;
  public showCalendar = false;
  public isLoggingOut = false;
  public isMobile = true;
  public isMdrBranch = false;

  public isDuringXmasHolidays: Boolean = false;
  private knowledgeBaseConfig: IKnowledgeBaseConfig;
  private _oauthObserver: AuthObserver;

  constructor(
    private router: Router,
    private menu: MenuController,
    public authService: AuthService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _storage: Storage,
    private _xmaxService: XmasService,
    private _attachmentNotificationFilterService: AttachmentNotificationFilterService,
    private readonly _chatMessagesStatusService: ChatMessagesStatusService,
    private _environmentService: EnvironmentService,
    private _orderNotificationFilterService: OrderNotificationFilterService,
    private _mobileOAuthService: MobileOAuthService,
    private _toastController: ToastController,
    private _tenantConfigService: TenantConfigService
  ) {
    this._environmentService.ready
      .then(() => {
        console.log('branch', this._environmentService.branch);
        this.isMasterBranch = this._environmentService.isProductionBranch();
        this.isMdrBranch = this._environmentService.isMdrBranch();
        this.branchName = this._environmentService.branch;
        this.version = this._environmentService.version;
      })
      .catch(err => console.error(err));
  }

  async ngOnInit() {
    await this._platform.ready();

    this.isMobile = this._platform.is('hybrid');

    const tenantConfig = await this._tenantConfigService.getTenantConfig();
    const showKnowledgeBase = await this._tenantConfigService.showKnowledgeBase();
    if (showKnowledgeBase) {
      this.knowledgeBaseConfig = await this._tenantConfigService.getKnowledgeBaseConfig();
    }
    this.showCalendar = await this._tenantConfigService.showCalendar();

    this.authService.authenticatedEventPublisher
      .pipe(takeWhile(authEventData => !authEventData.isAuthenticated))
      .subscribe({
        complete: () => {
          this.userIsAuthenticated = true;
          this.userIsChatAgent = Boolean(this.authService.authentication?.account?.isAgent);
          this.menuItems = MainMenu.get(
            !this._platform.is('hybrid'),
            true,
            this.authService,
            this._translateService,
            this.knowledgeBaseConfig?.menuItemName,
            tenantConfig
          );
        },
      });
    setTimeout(async () => {
      await this.setHelpPageDisturber();
    });
    this.setNotificationDisturber();
    this._chatMessagesStatusService.getTotalUnreadAgentMessagesCount().subscribe(unreadMessages => {
      this.unreadAgentMessages = clamp(unreadMessages, 0, 999);
    });

    this.isDuringXmasHolidays = this._xmaxService.isDuringXmasHolidays;
  }

  public onOpen() {}

  public onClose() {
    this.isMenuExpanded = false;
    this.selectedCategory = -1;
  }

  public async openPage(url: string) {
    this.onClose();
    if (url === '/formulare') {
      await Browser.open({ url: this._environmentService.swodocUrl, windowName: '_system' });
      await this.menu.close('mainNav');
      return;
    }
    if (url === '/knowledge-base') {
      await Browser.open({ url: this.knowledgeBaseConfig.link });
      await this.menu.close('mainNav');
      return;
    }

    await this.menu.close('mainNav');
    if (url) {
      await this.router.navigateByUrl(url);
    }
    if (url === '/notifications') {
      this.showNewNotifications = false;
    }
    if (url === '/help') {
      this.showHelpPageDisturber = false;
    }
  }

  togglePanel() {
    this.onClose();
    this.aiAssitantPanelIsOpen = !this.aiAssitantPanelIsOpen;
  }

  public async logout() {
    if (this.isLoggingOut) {
      return;
    }

    this.isLoggingOut = true;
    this.onClose();

    if (this._platform.is('hybrid')) {
      const logoutToast = await this._toastController.create({
        message: 'Du wirst abgemeldet...',
        position: 'bottom',
        buttons: [
          {
            text: 'Schließen',
            role: 'cancel',
          },
        ],
      });
      await logoutToast.present();
      this._oauthObserver = this._mobileOAuthService.addActionListener(action => this.onSignOutAction(action));
    }
    await this.authService.logout();
  }

  private setNotificationDisturber() {
    this.authService.authenticatedEventPublisher.subscribe((authEvent: IAuthEventData) => {
      if (!authEvent.isAuthenticated) {
        return;
      }
      if (this.authService.userIsRelevantDoctor) {
        this._orderNotificationFilterService.filter().subscribe(orders => {
          const unseenOrders = orders.filter(order => !order.hasBeenSeen);
          this.showNewNotifications = unseenOrders.length > 0;
          this.numberOfNewNotifications = unseenOrders.length;
        });
      } else {
        this._attachmentNotificationFilterService.filter().subscribe(attachments => {
          const attachmentNotHasBeenSeen = attachments.filter(attachment => !attachment.hasBeenSeen);
          this.showNewNotifications = attachmentNotHasBeenSeen.length > 0;
          this.numberOfNewNotifications = attachmentNotHasBeenSeen.length;
        });
      }
    });
  }

  private async onSignOutAction(action: IAuthAction) {
    this._mobileOAuthService.removeActionObserver(this._oauthObserver);

    if (action.action === AuthActions.SignOutSuccess) {
      window.location.reload();
    }
    if (action.action === AuthActions.SignOutFailed) {
      window.logger.log(`sing out failed - error: ${action.error}`);
      const toast = await this._toastController.create({
        message: action.error,
        duration: 10000,
        position: 'top',
        cssClass: 'login-toast',
      });
      await toast.present();
    }
  }

  private async setHelpPageDisturber() {
    const value = await this._storage.get('lastVersion');
    if (value === null) {
      this.showHelpPageDisturber = true;
    } else {
      const [majorUpdate, minorUpdate, pathUpdate] = UPDATES[0].version.split('.');
      const [majorStorage, minorStorage, pathStorage] = value.split('.');
      if (
        Number(majorUpdate) > Number(majorStorage) ||
        Number(minorUpdate) > Number(minorStorage) ||
        Number(pathUpdate) > Number(pathStorage)
      ) {
        this.showHelpPageDisturber = true;
      }
    }
  }
}
