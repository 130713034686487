import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { SortSelectionPopoverModule } from '../sort/sort-selection-popover.module';
import { AlbertaPageHeaderMenuComponent } from './alberta-page-header-menu/alberta-page-header-menu.component';
import { AlbertaPageHeaderComponent } from './alberta-page-header/alberta-page-header.component';
import { AlbertaPageSecondHeaderComponent } from './alberta-page-second-header/alberta-page-second-header.component';
import { AlbertaPageComponent } from './alberta-page.component';

@NgModule({
  declarations: [
    AlbertaPageComponent,
    AlbertaPageHeaderComponent,
    AlbertaPageSecondHeaderComponent,
    AlbertaPageHeaderMenuComponent,
  ],
  exports: [
    AlbertaPageComponent,
    AlbertaPageHeaderComponent,
    AlbertaPageSecondHeaderComponent,
    AlbertaPageHeaderMenuComponent,
  ],
  imports: [CommonModule, IonicModule, SortSelectionPopoverModule],
})
export class AlbertaPageModule {}
