import { AttachmentType } from '@alberta/konexi-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lowerFirst } from 'lodash';

@Pipe({
  name: 'attachmentTypePipe',
  pure: true,
})
export class AttachmentTypePipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}

  transform(attachmentType: AttachmentType): string {
    if (attachmentType === undefined) {
      return '';
    }
    let attachmentTypeName = '';
    attachmentTypeName = this._translate.instant(`attachment.type.${lowerFirst(AttachmentType[attachmentType])}`);
    if (attachmentTypeName.length === 0) {
      attachmentTypeName = this._translate.instant('careType.unknown');
    }

    return attachmentTypeName;
  }
}
