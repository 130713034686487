import { IModel } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { AppointmentChannel } from 'src/app/business/appointment/contracts/appointment-channel';
import { ArticleChannel } from 'src/app/business/article/contracts/article-channel';
import { CalendarResourceChannel } from 'src/app/business/calendar-resource/contracts/calendar-resource.channel';
import { CareProposalChannel } from 'src/app/business/care-proposal/contracts/care-proposal-channel';
import { ConfigChannel } from 'src/app/business/config/contract/config-channel';
import { DirectOrderChannel } from 'src/app/business/direct-order/contracts/direct-oder-channel';
import { ErpTaskChannel } from 'src/app/business/erpTask/contracts/erp-task-channel';
import { FaxChannel } from 'src/app/business/fax/contracts/fax-channel';
import { DoctorChannel } from 'src/app/business/institution/contracts/doctor.channel';
import { HospitalChannel } from 'src/app/business/institution/contracts/hospital.channel';
import { InstitutionAttributeChannel } from 'src/app/business/institution/contracts/institution-attribute.channel';
import { InstitutionAuditChannel } from 'src/app/business/institution/contracts/institution-audit.channel';
import { InstitutionContactChannel } from 'src/app/business/institution/contracts/institution-contact.channel';
import { InstitutionNoteChannel } from 'src/app/business/institution/contracts/institution-note.channel';
import { NursingHomeChannel } from 'src/app/business/institution/contracts/nursing-home.channel';
import { NursingServiceChannel } from 'src/app/business/institution/contracts/nursing-service.channel';
import { PharmacyChannel } from 'src/app/business/institution/contracts/pharmacy.channel';
import { SubunitChannel } from 'src/app/business/institution/contracts/subunit.channel';
import { InsuranceContractChannel } from 'src/app/business/insurance-contract/contracts/insurance-contract-channel';
import { IntegratedCareReturnDeliveryChannel } from 'src/app/business/integrated-care-return-delivery/contracts/integrated-care-return-delivery-channel';
import { IntegratedCareChannel } from 'src/app/business/integrated-care/contracts/integrated-care-channel';
import { MaintenanceChannel } from 'src/app/business/maintenance/contracts/maintenance-channel';
import { MedicalOrderRequestChannel } from 'src/app/business/medical-order/contracts/medical-order-request-channel';
import { OrderChannel } from 'src/app/business/order/contracts/order-channel';
import { PatientAppUserChannel } from 'src/app/business/patient-app-user/contracts/patient-app-user-channel';
import { PatientChannel } from 'src/app/business/patient/contracts/patient-channel';
import { PatientNotesChannel } from 'src/app/business/patientNotes/contracts/patientNotes-channel';
import { PlannedOrderChannel } from 'src/app/business/plannedOrder/contracts/planned-order-channel';
import { PortalNotificationChannel } from 'src/app/business/portal-notification/portal-notification-channel';
import { PrescriptionRequestChannel } from 'src/app/business/prescription/contracts/prescription-request-channel';
import { ProductGroupChannel } from 'src/app/business/product-group/contracts/product-group-channel';
import { ReminderChannel } from 'src/app/business/reminder/contract/reminder-channel';
import { ReturnDeliveryChannel } from 'src/app/business/return-delivery/contracts/return-delivery-channel';
import { ServiceChannel } from 'src/app/business/service-entity/contracts/service.channel';
import { ServiceOrderChannel } from 'src/app/business/service-order-entity/contracts/service-order.channel';
import { ServiceTaskChannel } from 'src/app/business/serviceTask/contracts/service-task-channel';
import { SingleOrderChannel } from 'src/app/business/single-order/single-order-channel';
import { StandardCareProposalChannel } from 'src/app/business/standard-careproposal/contracts/standard-careproposal-channel';
import { AuditChannel } from 'src/app/business/swodoc/contracts/audit-channel';
import { TemplateChannel } from 'src/app/business/swodoc/contracts/template-channel';
import { GroupChannel } from 'src/app/business/user-management/contracts/group-channel';
import { RegionChannel } from 'src/app/business/user-management/contracts/region-channel';
import { UsersChannel } from 'src/app/business/user/contracts/user.channel';
import {
  AppointmentModelName,
  ArticleModelName,
  AttachmentModelName,
  AuditModelName,
  CalendarResourceModelName,
  CareProposalModelName,
  ConfigModelName,
  DeviceModelName,
  DirectOrderModelName,
  DoctorModelName,
  ErpOrderModelName,
  ErpTaskModelName,
  FaxModelName,
  GroupModelName,
  HospitalModelName,
  InstitutionAttributeModelName,
  InstitutionAuditModelName,
  InstitutionContactModelName,
  InstitutionNoteModelName,
  InsuranceContractModelName,
  IntegratedCareModelName,
  IntegratedCareReturnDeliveryModelName,
  MaintenanceModelName,
  MedicalOrderRequestModelName,
  NursingHomeModelName,
  NursingServiceModelName,
  OrderModelName,
  PatientAppUserModelName,
  PatientModelName,
  PatientNoteModelName,
  PharmacyModelName,
  PortalNotificationModelName,
  PrescriptionRequestModelName,
  ProductGroupModelName,
  RegionModelName,
  ReminderModelName,
  ReturnDeliveryModelName,
  ServiceModelName,
  ServiceOrderModelName,
  ServiceTaskModelName,
  SingleOrderModelName,
  StandardCareProposalModelName,
  SubunitModelName,
  TemplateModelName,
  UsersModelName,
} from 'src/app/shared/models/model-names';

import { IState } from '../contracts/state/state';
import { IStateExtension } from '../contracts/state/state-extension';
import {
  AppointmentDB,
  ArticleDB,
  AttachmentDB,
  AuditDB,
  CalendarResourceDB,
  CareProposalDB,
  ConfigDB,
  DeviceDB,
  DirectOrderDB,
  DoctorDB,
  ErpOrderDB,
  ErpTaskDB,
  FaxDB,
  GroupDB,
  HospitalDB,
  InstitutionAttributeDB,
  InstitutionAuditDB,
  InstitutionContactDB,
  InstitutionNoteDB,
  InsuranceContractDB,
  IntegratedCareDB,
  IntegratedCareReturnDeliveryDB,
  MaintenanceDB,
  MedicalOrderRequestDB,
  NursingHomeDB,
  NursingServiceDB,
  OrderDB,
  PatientAppUserDB,
  PatientDB,
  PatientNotesDB,
  PharmacyDB,
  PlannedOrderDB,
  PortalNotificationDB,
  PrescriptionRequestDB,
  ProductGroupDB,
  RegionDB,
  ReminderDB,
  ReturnDeliveryDB,
  ServiceDB,
  ServiceOrderDB,
  ServiceTaskDB,
  SingleOrderDB,
  StandardCareProposalDB,
  SubunitDB,
  TemplateDB,
  UsersDB,
} from '../repository/databases';
import { StateExtensionToken } from '../state/state-extension-factory';
import { ObservableModel } from '../viewmodel/observable-model';
import { PlannedOrderModelName } from './../../shared/models/model-names';

const stateFactory = (extraStates?: [{ key: string; value: any }]) => {
  const state = {
    items: new ObservableModel([], false),
    selectedItem: new ObservableModel(undefined, false),
  };

  if (extraStates) {
    for (const extraState of extraStates) {
      state[extraState.key] = new ObservableModel(extraState.value, false);
    }
  }

  return state;
};

export interface IModelInfo<T extends IModel> {
  database: string;
  dto: string;
  channel: any;
  state: IState<IModel>;
  extensions?: IStateExtension<T>[];
}

@Injectable({ providedIn: 'root' })
export class ComponentModelInfo {
  private INFOS = {};

  constructor(@Inject(StateExtensionToken) private _stateExtensions) {
    this.INFOS[PatientModelName] = {
      database: PatientDB,
      dto: 'PatientDto',
      channel: PatientChannel,
      state: stateFactory([{ key: 'emergency', value: [] }]),
    };
    this.INFOS[InsuranceContractModelName] = {
      database: InsuranceContractDB,
      dto: 'InsuranceContractDto',
      channel: InsuranceContractChannel,
      state: stateFactory(),
    };
    this.INFOS[UsersModelName] = {
      database: UsersDB,
      dto: 'UserDto',
      channel: UsersChannel,
      state: stateFactory(),
    };
    this.INFOS[GroupModelName] = {
      database: GroupDB,
      dto: 'GroupDto',
      channel: GroupChannel,
      state: stateFactory(),
    };
    this.INFOS[RegionModelName] = {
      database: RegionDB,
      dto: 'RegionDto',
      channel: RegionChannel,
      state: stateFactory(),
    };
    this.INFOS[StandardCareProposalModelName] = {
      database: StandardCareProposalDB,
      dto: 'StandardCareProposalDto',
      channel: StandardCareProposalChannel,
      state: stateFactory(),
    };
    this.INFOS[CareProposalModelName] = {
      database: CareProposalDB,
      dto: 'CareProposalDto',
      channel: CareProposalChannel,
      state: stateFactory(),
    };
    this.INFOS[ProductGroupModelName] = {
      database: ProductGroupDB,
      dto: 'ProductGroupDto',
      channel: ProductGroupChannel,
      state: stateFactory(),
    };
    this.INFOS[ArticleModelName] = {
      database: ArticleDB,
      dto: 'ArticleDto',
      channel: ArticleChannel,
      state: stateFactory(),
    };
    this.INFOS[OrderModelName] = {
      database: OrderDB,
      dto: 'OrderDto',
      channel: OrderChannel,
      state: stateFactory([{ key: 'notification', value: [] }]),
      extensions: this._stateExtensions[OrderModelName],
    };
    this.INFOS[AuditModelName] = {
      database: AuditDB,
      dto: 'AuditDto',
      channel: AuditChannel,
      state: stateFactory([{ key: 'completed', value: [] }]),
      extensions: this._stateExtensions[AuditModelName],
    };
    this.INFOS[TemplateModelName] = {
      database: TemplateDB,
      dto: 'TemplateDto',
      channel: TemplateChannel,
      state: stateFactory(),
    };
    this.INFOS[PatientNoteModelName] = {
      database: PatientNotesDB,
      dto: 'PatientNoteDto',
      channel: PatientNotesChannel,
      state: stateFactory([{ key: 'dashboard', value: [] }]),
      extensions: this._stateExtensions[PatientNoteModelName],
    };
    this.INFOS[FaxModelName] = {
      database: FaxDB,
      dto: 'FaxDto',
      channel: FaxChannel,
      state: stateFactory(),
    };
    this.INFOS[ErpOrderModelName] = {
      database: ErpOrderDB,
      dto: 'ErpOrderDto',
      channel: undefined,
      state: stateFactory(),
    };
    this.INFOS[DeviceModelName] = {
      database: DeviceDB,
      dto: 'DeviceDto',
      channel: undefined,
      state: stateFactory(),
    };
    this.INFOS[AttachmentModelName] = {
      database: AttachmentDB,
      dto: 'AttachmentDto',
      channel: undefined,
      state: stateFactory([{ key: 'notification', value: [] }]),
      extensions: this._stateExtensions[AttachmentModelName],
    };
    this.INFOS[NursingHomeModelName] = {
      database: NursingHomeDB,
      dto: 'NursingHomeDto',
      channel: NursingHomeChannel,
      state: stateFactory(),
    };
    this.INFOS[DoctorModelName] = {
      database: DoctorDB,
      dto: 'DoctorDto',
      channel: DoctorChannel,
      state: stateFactory(),
    };
    this.INFOS[HospitalModelName] = {
      database: HospitalDB,
      dto: 'HospitalDto',
      channel: HospitalChannel,
      state: stateFactory(),
    };
    this.INFOS[InstitutionContactModelName] = {
      database: InstitutionContactDB,
      dto: 'InstitutionContactDto',
      channel: InstitutionContactChannel,
      state: stateFactory(),
    };
    this.INFOS[InstitutionNoteModelName] = {
      database: InstitutionNoteDB,
      dto: 'InstitutionNoteDto',
      channel: InstitutionNoteChannel,
      state: stateFactory([{ key: 'dashboard', value: [] }]),
      extensions: this._stateExtensions[InstitutionNoteModelName],
    };
    this.INFOS[InstitutionAuditModelName] = {
      database: InstitutionAuditDB,
      dto: 'InstitutionAuditDto',
      channel: InstitutionAuditChannel,
      state: stateFactory(),
      extensions: this._stateExtensions[InstitutionAuditModelName],
    };
    this.INFOS[PharmacyModelName] = {
      database: PharmacyDB,
      dto: 'PharmacyDto',
      channel: PharmacyChannel,
      state: stateFactory(),
    };
    this.INFOS[NursingServiceModelName] = {
      database: NursingServiceDB,
      dto: 'NursingServiceDto',
      channel: NursingServiceChannel,
      state: stateFactory(),
    };
    this.INFOS[MaintenanceModelName] = {
      database: MaintenanceDB,
      dto: 'MaintenanceDto',
      channel: MaintenanceChannel,
      state: stateFactory(),
    };
    this.INFOS[SubunitModelName] = {
      database: SubunitDB,
      dto: 'SubunitDto',
      channel: SubunitChannel,
      state: stateFactory(),
    };
    this.INFOS[ReturnDeliveryModelName] = {
      database: ReturnDeliveryDB,
      dto: 'ReturnDeliveryDto',
      channel: ReturnDeliveryChannel,
      state: stateFactory(),
    };
    this.INFOS[ConfigModelName] = {
      database: ConfigDB,
      dto: 'ConfigDto',
      channel: ConfigChannel,
      state: stateFactory(),
    };
    this.INFOS[AppointmentModelName] = {
      database: AppointmentDB,
      dto: 'AppointmentDto',
      channel: AppointmentChannel,
      state: stateFactory([{ key: 'dashboard', value: [] }]),
      extensions: this._stateExtensions[AppointmentModelName],
    };
    this.INFOS[ErpTaskModelName] = {
      database: ErpTaskDB,
      dto: 'ErpTaskDto',
      channel: ErpTaskChannel,
      state: stateFactory(),
    };
    this.INFOS[IntegratedCareModelName] = {
      database: IntegratedCareDB,
      dto: 'IntegratedCareDto',
      channel: IntegratedCareChannel,
      state: stateFactory(),
      extensions: this._stateExtensions[IntegratedCareModelName],
    };
    this.INFOS[PatientAppUserModelName] = {
      database: PatientAppUserDB,
      dto: 'PatientAppUserDto',
      channel: PatientAppUserChannel,
      state: stateFactory(),
    };
    this.INFOS[SingleOrderModelName] = {
      database: SingleOrderDB,
      dto: 'SingleOrderDto',
      channel: SingleOrderChannel,
      state: stateFactory([{ key: 'notification', value: [] }]),
      extensions: this._stateExtensions[SingleOrderModelName],
    };
    this.INFOS[DirectOrderModelName] = {
      database: DirectOrderDB,
      dto: 'DirectOrderDto',
      channel: DirectOrderChannel,
      state: stateFactory([{ key: 'notification', value: [] }]),
      extensions: this._stateExtensions[DirectOrderModelName],
    };
    this.INFOS[ReminderModelName] = {
      database: ReminderDB,
      dto: 'ReminderDto',
      channel: ReminderChannel,
      state: stateFactory([{ key: 'dashboard', value: [] }]),
      extensions: this._stateExtensions[ReminderModelName],
    };
    this.INFOS[PlannedOrderModelName] = {
      database: PlannedOrderDB,
      dto: 'PlannedOrderDto',
      channel: PlannedOrderChannel,
      state: stateFactory(),
    };
    this.INFOS[IntegratedCareReturnDeliveryModelName] = {
      database: IntegratedCareReturnDeliveryDB,
      dto: 'IntegratedCareReturnDeliveryDto',
      channel: IntegratedCareReturnDeliveryChannel,
      state: stateFactory(),
    };
    this.INFOS[InstitutionAttributeModelName] = {
      database: InstitutionAttributeDB,
      dto: 'InstitutionAttributeDto',
      channel: InstitutionAttributeChannel,
      state: stateFactory(),
    };
    this.INFOS[PrescriptionRequestModelName] = {
      database: PrescriptionRequestDB,
      dto: 'PrescriptionRequestDto',
      channel: PrescriptionRequestChannel,
      state: stateFactory(),
    };
    this.INFOS[MedicalOrderRequestModelName] = {
      database: MedicalOrderRequestDB,
      dto: 'MedicalOrderRequestDto',
      channel: MedicalOrderRequestChannel,
      state: stateFactory(),
    };
    this.INFOS[PortalNotificationModelName] = {
      database: PortalNotificationDB,
      dto: 'PortalNotificationDto',
      channel: PortalNotificationChannel,
      state: stateFactory(),
    };
    this.INFOS[ServiceModelName] = {
      database: ServiceDB,
      dto: 'ServiceDto',
      channel: ServiceChannel,
      state: stateFactory(),
    };
    this.INFOS[ServiceOrderModelName] = {
      database: ServiceOrderDB,
      dto: 'ServiceOrderDto',
      channel: ServiceOrderChannel,
      state: stateFactory(),
      extensions: this._stateExtensions[ServiceOrderModelName],
    };
    this.INFOS[ServiceTaskModelName] = {
      database: ServiceTaskDB,
      dto: 'ServiceTaskDto',
      channel: ServiceTaskChannel,
      state: stateFactory(),
    };
    this.INFOS[CalendarResourceModelName] = {
      database: CalendarResourceDB,
      dto: 'CalendarResourceDto',
      channel: CalendarResourceChannel,
      state: stateFactory(),
    };
  }

  public getInfo(modelName: string) {
    if (!(modelName in this.INFOS)) {
      throw new Error(`No Info registered for modelName ${modelName}.`);
    }

    return this.INFOS[modelName];
  }
}
