import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { StatusBarService } from 'src/app/shared/services/statusbar.service';

@Component({
  selector: 'itl-alberta-page-header',
  templateUrl: 'alberta-page-header.component.html',
  styleUrls: ['./alberta-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlbertaPageHeaderComponent implements OnInit {
  @Input() header: string;
  @Input() secondHeader: string;
  @Output() backButtonPressed: EventEmitter<void> = new EventEmitter<void>();
  @Output() actionMenuButtonPressed: EventEmitter<void> = new EventEmitter<void>();
  constructor(private _statusBarService: StatusBarService) {}

  ngOnInit() {
    this._statusBarService.setColorFromCssVariable('--alberta-color-primary');
  }

  goBack($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.backButtonPressed.emit();
  }

  menuAction($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.actionMenuButtonPressed.emit();
  }
}
