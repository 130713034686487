<itl-alberta-page-header
  (backButtonPressed)="goBack()"
  (menuActionButtonPressed)="menuAction()"
  [header]="header"
  [secondHeader]="secondHeader"
>
  <ng-content header-menu select="itl-alberta-page-header-menu"></ng-content>
  <ng-content second-header select="itl-alberta-page-second-header"></ng-content>
</itl-alberta-page-header>
<div [class]="{ 'safe-insets': useInsets }" class="page-content">
  <ng-content> </ng-content>
</div>
<div class="footer">
  <ng-container *ngTemplateOutlet="footer"></ng-container>
</div>
