import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { Deferred } from 'src/app/common/deferred/deferred';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';
import { PatientAppUserChannelDB } from 'src/app/common/repository/databases';

import { PatientAppUserModelName } from '../../models/model-names';
import { IPatientAppUser } from '../../models/patient-app/patient-app-user';
import { IDatabaseService } from '../contracts/database/database-service';
import { IGenericStorage } from '../contracts/database/generic-storage';
import { DatabaseService } from '../database.service';

@Injectable({ providedIn: 'root' })
export class PatientAppUserSynchronizer {
  private _ready = new Deferred<void>();
  private _patientAppUserChannelDb: IGenericStorage;
  private _syncReady$ = new BehaviorSubject<void>(null);

  public get patientAppSyncReady(): Observable<void> {
    return this._syncReady$.asObservable();
  }

  constructor(
    @Inject(DatabaseService) private _databaseService: IDatabaseService,
    @Inject(Dispatcher) private _dispatcher: IDispatcher<IPatientAppUser>
  ) {
    // tslint:disable-next-line: no-floating-promises
    this._databaseService.getDatabase(PatientAppUserChannelDB).then(storage => {
      this._patientAppUserChannelDb = storage;
      this._ready.resolve();
    });
  }
  public async synchronize(patientAppUsers: IPatientAppUser[]) {
    if (!patientAppUsers?.length) {
      this._syncReady$.next();
      return;
    }

    await this._ready.promise;

    const patientAppChannelUsers = patientAppUsers
      .filter(patientAppUser => patientAppUser.channelSid)
      .map(patientAppUser => ({ ...patientAppUser, _id: patientAppUser.channelSid }));

    await this._patientAppUserChannelDb.setItems({ items: patientAppChannelUsers, deletable: true });
    for (const user of patientAppUsers) {
      await this._dispatcher.createState(PatientAppUserModelName, user);
    }
    await this._dispatcher.updateState(PatientAppUserModelName, patientAppUsers);

    this._syncReady$.next();
  }
}
