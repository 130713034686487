import { IMaintenance } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { isEqual } from 'lodash';
import hash from 'object-hash';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { IShutdownBroker } from 'src/app/business/maintenance/contracts/shutdown-broker';
import { IShutdownInfo } from 'src/app/business/maintenance/contracts/shutdown-info';
import { ISyncStatus } from 'src/app/common/contracts/sync/sync-status';
import { SyncStatus } from 'src/app/common/sync/sync-status';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MaintenanceService } from 'src/app/shared/services/maintenance/maintenance.service';
import { ShutdownBroker } from 'src/app/shared/services/maintenance/shutdown-broker.service';

import { SelectModalComponent } from '../../common/select-modal/select-modal.component';
import { MaintenanceInfoModalComponent } from './maintenance-info-modal';

@Injectable({ providedIn: 'root' })
export class MaintenanceInfoService {
  private _lastShutdownInfo: IShutdownInfo;
  private _maintenanceModal: HTMLIonModalElement;
  private _popupHashes = [];

  constructor(
    @Inject(SyncStatus) private _syncStatus: ISyncStatus,
    private _modalController: ModalController,
    private _authService: AuthService,
    private _maintenanceService: MaintenanceService,
    @Inject(ShutdownBroker) private _shutdownBroker: IShutdownBroker
  ) {
    this.init();
  }

  private init(): void {
    this._authService.authenticatedEventPublisher
      .pipe(
        mergeMap(authEventData =>
          this._syncStatus.completedSync$.pipe(map(complete => ({ ...authEventData, complete })))
        ),
        filter(authEventData => authEventData.isAuthenticated && (authEventData.authOnline || !authEventData.complete)),
        take(1),
        switchMap(() => this._shutdownBroker.shutdownWatch),
        filter(
          shutdownInfo =>
            shutdownInfo && shutdownInfo.showPopUp && shutdownInfo.maintenance && !shutdownInfo.maintenance.stopped
        ),
        filter(shutdownInfo => !isEqual(this._lastShutdownInfo, shutdownInfo)),
        filter(shutdownInfo => {
          const shutdownInfoHash = hash(shutdownInfo);
          if (this._popupHashes.includes(shutdownInfoHash)) {
            return false;
          } else {
            this._popupHashes.push(shutdownInfoHash);
            return true;
          }
        }),
        tap(shutdownInfo => (this._lastShutdownInfo = shutdownInfo))
      )
      .subscribe(async shutdownInfo => {
        if (this._maintenanceService.isRelevantMaintenance(shutdownInfo.maintenance)) {
          await this.openInfoModal(shutdownInfo.maintenance);
        }
      }, console.error);
  }

  private async openInfoModal(maintenance: IMaintenance) {
    if (this._maintenanceModal) {
      this._maintenanceModal.dismiss().catch(console.error);
    }

    this._maintenanceModal = await this._modalController.create({
      backdropDismiss: false,
      component: SelectModalComponent,
      componentProps: {
        header: 'Wartungshinweis',
        buttonText: 'OK, verstanden',
        _content: MaintenanceInfoModalComponent,
        _dontCheckForChange: true,
        _componentProps: {
          startDate: maintenance.start,
        },
      },
      cssClass: 'selectModal maintenanceInfoModal',
    });
    await this._maintenanceModal.present();
  }
}
