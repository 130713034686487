import { IConfig, Permission } from '@alberta/konexi-shared';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'src/app/common/menu/menu-item';

import {
  ArticleModelName,
  GroupModelName,
  InstitutionModelName,
  InsuranceContractModelName,
  PatientModelName,
  ProductGroupModelName,
  RegionModelName,
  StandardCareProposalModelName,
  TemplateModelName,
  UsersModelName,
} from '../../models/model-names';
import { AuthService } from '../../services/auth.service';

export class MainMenu {
  public static get(
    isWebApp: boolean,
    isHackathon = false,
    authService: AuthService,
    translateService: TranslateService,
    knowledgeMenuItemName: string,
    tenantConfig: IConfig
  ): MenuItem[] {
    const menuItems = [];
    menuItems.push(new MenuItem('Startseite', 'alberta-dashboard', '/dashboard'));
    if (authService.userHasPermission(PatientModelName, Permission.Read)) {
      menuItems.push(new MenuItem('Patienten', 'alberta-person', '/patient'));
    }

    const showOrderList =
      tenantConfig?.orderListConfig?.enabled && authService.userHasPermission('orderList', Permission.Read);
    const showDocumentList =
      tenantConfig?.documentListConfig?.enabled && authService.userHasPermission('documentList', Permission.Read);
    const showServiceTaskList =
      tenantConfig?.showServices &&
      tenantConfig?.serviceTaskListConfig?.enabled &&
      authService.userHasPermission('serviceTaskList', Permission.Read);
    const showServiceOrderList =
      tenantConfig?.showServices &&
      tenantConfig?.serviceOrderListConfig?.enabled &&
      authService.userHasPermission('serviceOrderList', Permission.Read);
    if (isHackathon) {
      menuItems.push(new MenuItem('AI Assistant', 'alberta-chat'));
    }
    if (showOrderList || showDocumentList || showServiceTaskList || showServiceOrderList) {
      const listMenu = new MenuItem('Listen', 'alberta-lists');
      if (showOrderList) {
        listMenu.addChild(new MenuItem('Bestellungen', 'alberta-shoppingcart-full', '/list/orders'));
      }
      if (showDocumentList) {
        listMenu.addChild(new MenuItem('Dokumente', 'alberta-swodoc', '/list/documents'));
      }

      if (showServiceTaskList) {
        listMenu.addChild(
          new MenuItem(translateService.instant('entities.serviceTask'), 'alberta-calendar-check', '/list/service-task')
        );
      }
      if (showServiceOrderList) {
        listMenu.addChild(
          new MenuItem(
            translateService.instant('entities.serviceOrder'),
            'alberta-service-order',
            '/list/service-order'
          )
        );
      }
      menuItems.push(listMenu);
    }

    if (!isWebApp && authService.userHasPermission('onlineSearch', Permission.Read)) {
      menuItems.push(new MenuItem('Online-Suche', 'alberta-person', '/emergency'));
    }
    const notificationMenu = new MenuItem('Mitteilungen', 'alberta-chat');
    notificationMenu.addChild(new MenuItem('Fax-Protokoll', 'alberta-fax', '/fax'));
    menuItems.push(notificationMenu);

    this.setMangementPages(authService, menuItems, isWebApp);
    this.setInstitutionPages(authService, menuItems);
    if (isWebApp && authService.userHasPermission(TemplateModelName, Permission.Read)) {
      menuItems.push(new MenuItem('Formulare', 'alberta-swodoc', '/formulare'));
    }
    const settingsMenu = new MenuItem('Einstellungen', 'alberta-setting');
    settingsMenu.addChild(new MenuItem('Allgemein', 'alberta-hammer', '/setting'));
    if (authService.userHasPermission('calendar', Permission.Read)) {
      settingsMenu.addChild(new MenuItem('Kalender', 'alberta-activities', '/setting/calendar'));
    }
    if (isWebApp) {
      if (this.hasAnyUsermanagementPermission(authService)) {
        settingsMenu.addChild(new MenuItem('Rechtemanagement', 'alberta-key', '/rights-management'));
      }
      if (this.userIsItLabs(authService)) {
        settingsMenu.addChild(new MenuItem('Wartungsmanagement', 'alberta-clock', '/maintenance'));
      }
      if (authService.authentication.account.isAgent) {
        settingsMenu.addChild(new MenuItem('Patienten-App', 'alberta-support', '/agent-admin'));
      }
    }
    menuItems.push(settingsMenu);

    if (knowledgeMenuItemName) {
      menuItems.push(new MenuItem(knowledgeMenuItemName, 'alberta-website-filled', '/knowledge-base'));
    }
    return menuItems;
  }
  private static setInstitutionPages(authService: AuthService, menuItems: MenuItem[]) {
    if (authService.userHasPermission(InstitutionModelName, Permission.Read)) {
      const instituionsMenu = new MenuItem('CRM', 'alberta-crm');

      instituionsMenu.addChild(new MenuItem('Apotheken', 'alberta-pharmacy', '/pharmacy'));
      instituionsMenu.addChild(new MenuItem('Ärzte', 'alberta-doctor', '/doctor'));
      instituionsMenu.addChild(new MenuItem('Kliniken', 'alberta-hospital', '/hospital'));
      instituionsMenu.addChild(new MenuItem('Pflegedienste', 'alberta-nursingservice', '/nursing-service'));
      instituionsMenu.addChild(new MenuItem('Pflegeheime', 'alberta-nursinghome', '/nursing-home'));

      menuItems.push(instituionsMenu);
    }
  }
  private static setMangementPages(authService: AuthService, menuItems: MenuItem[], isWebApp) {
    if (isWebApp) {
      if (authService.userHasPermission(InsuranceContractModelName, Permission.Read)) {
        menuItems.push(new MenuItem('Verträge', 'alberta-contract', '/insurance-contract'));
      }
    }
    const productMenu = new MenuItem('Produkte', 'alberta-produce');
    if (authService.userHasPermission(ArticleModelName, Permission.Read)) {
      productMenu.addChild(new MenuItem('Artikel', 'alberta-article', isWebApp ? '/article' : '/article-mobile'));
    }
    if (isWebApp) {
      if (authService.userHasPermission(ProductGroupModelName, Permission.Read)) {
        productMenu.addChild(new MenuItem('Warengruppen', 'alberta-productgroup', '/product-group'));
      }
      if (authService.userHasPermission(StandardCareProposalModelName, Permission.Read)) {
        productMenu.addChild(new MenuItem('Standard-VV', 'alberta-stdcareproposal', '/standard-care-proposal'));
      }
    }
    if (productMenu.children && productMenu.children.length > 0) {
      menuItems.push(productMenu);
    }
  }

  private static hasAnyUsermanagementPermission(authService: AuthService) {
    return (
      authService.userHasPermission(UsersModelName, Permission.Read) ||
      authService.userHasPermission(GroupModelName, Permission.Read) ||
      authService.userHasPermission(RegionModelName, Permission.Read)
    );
  }

  private static userIsItLabs(authService) {
    return (
      authService &&
      authService.authentication &&
      authService.authentication.account &&
      authService.authentication.account.organization &&
      authService.authentication.account.organization.tenantId === '1TLabs'
    );
  }
}
