<div class="h-full flex flex-col flex-auto overflow-y-auto lg:overflow-hidden bg-card dark:bg-default">
  <ng-container *ngIf="chat; else selectChatOrStartNew">
    <!-- Header -->
    <itl-alberta-page-header [header]="'Alberta Bot'" (backButtonPressed)="backButtonPressed.emit()">
    </itl-alberta-page-header>

    <!-- Conversation -->
    <div class="flex overflow-y-auto overflow-x-hidden flex-col-reverse">
      <div class="flex flex-col flex-auto shrink p-6 bg-card dark:bg-transparent">
        <ng-container
          *ngFor="let message of chat.messages; let i = index; let first = first; let last = last; trackBy: trackByFn"
        >
          <!-- Start of the day -->
          <ng-container
            *ngIf="first || (chat.messages[i - 1].createdAt | date : 'd') !== (message.createdAt | date : 'd')"
          >
            <div class="flex items-center justify-center my-3 -mx-6">
              <div class="flex-auto border-b"></div>
              <div class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary">
                {{ message.createdAt | date : 'longDate' }}
              </div>
              <div class="flex-auto border-b"></div>
            </div>
          </ng-container>
          <div
            class="flex flex-col"
            [ngClass]="{
              'items-end': message.isMine,
              'items-start': !message.isMine,
              'mt-0.5': i > 0 && chat.messages[i - 1].isMine === message.isMine,
              'mt-3': i > 0 && chat.messages[i - 1].isMine !== message.isMine
            }"
          >
            <!-- Bubble -->
            <div
              class="relative max-w-3/4 px-3 py-2 rounded-lg"
              [ngClass]="{ 'bg-blue-500 text-blue-50': message.isMine, 'bg-gray-500 text-gray-50': !message.isMine }"
            >
              <!-- Speech bubble tail -->
              <ng-container *ngIf="last || chat.messages[i + 1].isMine !== message.isMine">
                <div
                  class="absolute bottom-0 w-3"
                  [ngClass]="{
                    'text-blue-500 -right-1 -mr-px mb-px': message.isMine,
                    'text-gray-500 -left-1 -ml-px mb-px -scale-x-1': !message.isMine
                  }"
                >
                  <ng-container *ngTemplateOutlet="speechBubbleExtension"></ng-container>
                </div>
              </ng-container>
              <!-- Message -->
              <div class="min-w-4 leading-5" [innerHTML]="message.value"></div>
            </div>
            <!-- Time -->
            <ng-container
              *ngIf="
                first ||
                last ||
                chat.messages[i + 1].isMine !== message.isMine ||
                chat.messages[i + 1].createdAt !== message.createdAt
              "
            >
              <div
                class="my-0.5 text-sm font-medium text-secondary"
                [ngClass]="{ 'mr-3': message.isMine, 'ml-3': !message.isMine }"
              >
                {{ message.createdAt | date : 'HH:mm' }}
              </div>
            </ng-container>
          </div>
        </ng-container>
        <!-- TypingIndicator -->
        <div class="flex flex-col items-start mt-3" *ngIf="chat.isBotTyping">
          <div class="relative max-w-3/4 px-3 py-2 rounded-lg bg-gray-500 text-gray-50">
            <!-- Speech bubble tail -->
            <div class="absolute bottom-0 w-3 text-gray-500 -left-1 -ml-px mb-px -scale-x-1">
              <ng-container *ngTemplateOutlet="speechBubbleExtension"></ng-container>
            </div>
            <!-- Message -->
            <div class="min-w-4 leading-5">
              <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Message field -->
    <div class="flex items-end p-4 border-t bg-gray-50 dark:bg-transparent">
      <div class="flex-1">
        <textarea cdkTextareaAutosize #messageInput class="p-2 w-full" (keydown)="handleEnter($event)"></textarea>
      </div>
      <div class="ml-3 flex items-center h-11 my-px">
        <itl-button (click)="sendMessage()" class=""
        >
          <itl-alberta-icon name="alberta-fab-resend"></itl-alberta-icon
          >
        </itl-button>
      </div>
    </div>
  </ng-container>

  <!-- Select chat or start new template -->
  <ng-template #selectChatOrStartNew>
    <div class="flex flex-col flex-auto items-center justify-center bg-gray-100 dark:bg-transparent">
      <!-- <mat-icon
              class="icon-size-24"
              [svgIcon]="'heroicons_outline:chat-bubble-oval-left-ellipsis'"></mat-icon> -->
      <div class="mt-4 text-2xl font-semibold tracking-tight text-secondary">
        Select a conversation or start a new chat
      </div>
    </div>
  </ng-template>

  <!-- Speech bubble tail SVG -->
  <!-- @formatter:off -->
  <ng-template #speechBubbleExtension>
    <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z"
          fill="currentColor"
          fill-rule="nonzero"
        ></path>
      </g>
    </svg>
  </ng-template>
  <!-- @formatter:on -->
</div>
