import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AlbertaIconModule } from 'src/app/shared/components/alberta-icon/alberta-icon.module';
import { AlbertaCommonComponentsModule } from 'src/app/shared/components/common/common-components.module';

import { SortSelectionPopoverComponent } from './sort-selection-popover.component';

@NgModule({
  declarations: [SortSelectionPopoverComponent],
  imports: [TranslateModule, IonicModule, CommonModule, AlbertaIconModule, AlbertaCommonComponentsModule],
  exports: [SortSelectionPopoverComponent],
})
export class SortSelectionPopoverModule {}
