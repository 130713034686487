<div class="mainRow">
  <div tappable class="backbutton" (click)="goBack($event)">
    <ion-icon name="arrow-back" slot="icon-only"></ion-icon>
  </div>
  <div class="header-wrapper">
    <div class="header">{{ header }}</div>
    <div class="secondHeader" *ngIf="secondHeader">{{ secondHeader }}</div>
  </div>
  <div tappable class="headerMenu" (click)="menuAction($event)">
    <ng-content select=[header-menu]></ng-content>
  </div>
</div>
<div class="secondRow">
  <ng-content select=[second-header]></ng-content>
</div>
