import { SortDirection } from 'src/app/common/sort/sort-direction';

import { SelectionItem } from './selectionItem';

export class SortSelectionItem extends SelectionItem {
  constructor(
    public column: string,
    public value: string,
    public displayValue: string,
    public icon: string,
    public direction: SortDirection
  ) {
    super(column, value);
  }
}
