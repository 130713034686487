<itl-info-box [type]="infoBoxTypeWarning" *ngIf="maxAmountExceeded"
  >{{ 'validation.warning.maxAmount' | translate }}
</itl-info-box>
<!--Article-->
<itl-detail-item *ngIf="article" [noIcon]="!hasCheckbox && !icon">
  <itl-detail-item-icon>
    <div *ngIf="hasCheckbox" class="checkbox-wrapper" (click)="this.toggleCheckbox()">
      <ion-checkbox [checked]="isChecked"></ion-checkbox>
    </div>
    <itl-alberta-icon *ngIf="icon" [class]="iconColor" [name]="icon"></itl-alberta-icon>
  </itl-detail-item-icon>
  <div
    class="bold"
    [ngStyle]="{
    'text-decoration': article.archived ? 'line-through' : 'unset'
  }"
    (click)="this.toggleCheckbox()"
  >
    <span class="article-name">{{ article?.name }}</span>
  </div>
  <!--Quantity-->
  <div [ngClass]="isWizardPage == true ? 'secondary-variant' : 'secondary'" class="bold quantity">
    <ng-content select="[slot=dosage]"></ng-content>
  </div>
  <!--RatingStars-->
  <div class="rating-stars">
    <itl-rating-stars
      *ngIf="showRatingStars"
      [rating]="article.calculatedStars"
      [assortmentType]="article.assortmentType"
    >
    </itl-rating-stars>
  </div>
  <!--Label-->
  <div class="label-container">
    <ng-content select="[slot=deliveredQuantity]"></ng-content>
    <itl-label
      *ngIf="labelStatus"
      class="status-label"
      [showInfoBox]="article?.statusAnnotation ? true : false"
      [infoMessage]="article?.statusAnnotation"
      [infoHeader]="getInfoHeader(article)"
      [baseColor]="getBaseColor(article)"
      >{{ article?.status | articleStatusPipe }} {{ this.getUnavailableFrom(article?.unavailableFrom) }} {{
      this.getUnavailableTo(article?.unavailableTo) }} {{ this.getDelayedTo(article?.delayedTo) }}</itl-label
    >
    <itl-label *ngIf="stock" [baseColor]="getStockBaseColor(stock)">
      {{ 'article.details.stock' | translate }}: {{stock.quantity}}
      <span
        >&nbsp;{{(stock.packagingUnit ? stock.packagingUnit : packagingUnitTypePiece) | articleUnitPipe}}</span
      ></itl-label
    >
    <itl-label *ngIf="labelIsPrivateSale" [labelStyle]="'infoLabel'" [icon]="'alberta-private-sale-order'"
      >{{ 'article.details.isPrivateSale' | translate }}
    </itl-label>
    <itl-label *ngIf="labelNotAutomaticDelivery" [labelStyle]="'infoLabel'" [icon]="'alberta-not-automatic-delivery'"
      >{{ 'article.details.notAutomaticDelivery' | translate }}
    </itl-label>
    <itl-label *ngIf="article?.pharmacyOnly" [labelStyle]="'infoLabel'" [icon]="'alberta-pharmacy-only'"
      >{{ 'article.details.pharmacyOnly' | translate }}
    </itl-label>
  </div>
  <!--Cards-->
  <div class="card-container">
    <itl-card [header]="'device.articleDescription'| translate">
      {{ article?.description ? article?.description : 'k.A.' }}
    </itl-card>
    <itl-card [header]=" 'article.details.additionalDescription' | translate ">
      {{ article?.additionalDescription ? article?.additionalDescription : 'k.A.' }}
    </itl-card>
    <itl-card [header]="'order.edit.customerNumber' | translate">
      {{ article?.customerNumber ? article?.customerNumber : 'k.A.'}}
    </itl-card>
    <itl-card [header]=" 'directOrder.pcn' | translate "> {{ article?.pcn ? article?.pcn : 'k.A.' }} </itl-card>
    <itl-card [header]=" 'directOrder.manufacturerNumber' | translate ">
      {{ article?.manufacturerNumber ? article?.manufacturerNumber : 'k.A.' }}
    </itl-card>
    <ng-content select="[slot=cards]"></ng-content>
  </div>
  <!--AdditionalCards-->
  <div *ngIf="articleExpanded" class="card-container">
    <itl-card *ngIf="article.manufacturer" [header]="'article.details.manufacturer' | translate">
      {{ article.manufacturer }}
    </itl-card>
    <itl-card *ngIf="article?.medicalAidPositionNumber" [header]="'order.edit.medicalAidPositionNumber' | translate">
      {{ article?.medicalAidPositionNumber }}
    </itl-card>
    <itl-card *ngIf="article?.ean" [header]="'order.edit.ean' | translate"> {{ article?.ean }} </itl-card>
    <itl-card *ngIf="article?.size" [header]="'article.details.size' | translate"> {{ article?.size }} </itl-card>
    <itl-card *ngIf="article?.inventoryType" [header]="'order.edit.articleType' | translate">
      {{ article?.inventoryType | articleInventoryTypePipe }}
    </itl-card>
    <ng-content select="[slot=additionalCards]"></ng-content>
  </div>
  <!--ShowMoreButton-->
  <div class="chevron-stock-buttons-wrapper">
    <itl-card
      class="show-more-button"
      *ngIf="!hideChevron && (hasAdditionalCards ||article.manufacturer || article?.inventoryType || article?.medicalAidPositionNumber || article?.ean || article?.size)"
      (click)="toggleShowMore()"
      ><itl-alberta-icon [name]="this.articleExpanded? 'chevron-up-outline' : 'chevron-down-outline'"></itl-alberta-icon
    ></itl-card>
    <ng-content select="itl-check-stock-button"></ng-content>
  </div>
</itl-detail-item>
<itl-info-box class="article-is-delayed-info-box" *ngIf="articleIsDelayed" [type]="infoBoxTypeInformation">
  {{ 'validation.warning.articleIsDelayed' | translate }} {{ delayedTo }}
</itl-info-box>
