import { CommonModule, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  NgZone, OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { AlbertaIconModule } from 'src/app/shared/components/alberta-icon/alberta-icon.module';
import { AlbertaPageModule } from 'src/app/shared/components/alberta-page/alberta-page.module';
import { AlbertaInputsModule } from 'src/app/shared/components/inputs/inputs-components.module';
import { ChatAssistantService } from './chat-assistant.service';
import { Chat } from './model/chat.model';
import { v4 } from 'uuid';
import { DateTime } from 'luxon';

@Component({
  selector: 'itl-chat-assistant',
  templateUrl: './chat-assistant.component.html',
  styleUrls: ['./chat-assistant.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [DatePipe, CommonModule, AlbertaInputsModule, AlbertaIconModule, AlbertaPageModule],
})
export class ChatAssistantComponent implements OnInit, OnDestroy {

  @Output() backButtonPressed = new EventEmitter();
  @ViewChild('messageInput') messageInput: ElementRef;
  chat: Chat;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _chatAssistantService: ChatAssistantService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ngZone: NgZone,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Decorated methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resize on 'input' and 'ngModelChange' events
   *
   * @private
   */
  @HostListener('input')
  @HostListener('ngModelChange')
  private _resizeMessageInput(): void {
    // This doesn't need to trigger Angular's change detection by itself
    this._ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        // Set the height to 'auto' so we can correctly read the scrollHeight
        this.messageInput.nativeElement.style.height = 'auto';

        // Detect the changes so the height is applied
        this._changeDetectorRef.detectChanges();

        // Get the scrollHeight and subtract the vertical padding
        this.messageInput.nativeElement.style.height = `${this.messageInput.nativeElement.scrollHeight}px`;

        // Detect the changes one more time to apply the final height
        this._changeDetectorRef.detectChanges();
      });
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.resetChat();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  handleEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  resetChat(): void {
    this.chat = {
      messages: [],
      isBotTyping: false,
    };
    this.addMessage('Was möchtest du wissen?', false);
  }

  sendMessage(): void {
    const text = this.messageInput.nativeElement.value;
    this.messageInput.nativeElement.value = '';
    this.messageInput.nativeElement.focus();
    this.addMessage(text, true);
    this.chat.isBotTyping = true;
    this._chatAssistantService.sendMessage(text).subscribe((response: any) => {
      this.chat.isBotTyping = false;
      this.addMessage(response.message, false);
    });
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  addMessage(message: string, isMine: boolean): void {
    this.chat.messages.push({
      id: v4(),
      isMine,
      value: message,
      createdAt: DateTime.now().toISO()
    });
    this._changeDetectorRef.markForCheck();
  }
}
