import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IBaseInputContract } from '../contracts/base-input';

@Component({
  selector: 'itl-numberUpDown',
  templateUrl: 'number-up-down.component.html',
  styleUrls: ['../_templates/text-input-template/text-input-template-alb-class.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberUpDownComponent implements IBaseInputContract, ControlValueAccessor {
  @Input() public title: string;
  @Input() public disabled = false;
  @Input() public icon: string;
  @Input() public noIcon = false;
  @Input() public placeholder = '';
  @Input() public required: boolean;
  @Input() public description: string;
  @Input() public min: number;
  @Output() public changed: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('input', { static: true }) public input: ElementRef;

  public value;
  private onChange = (text: string) => {};
  private onTouched = () => {};

  constructor(@Optional() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public setFocus() {
    this.input.nativeElement.focus();
  }

  public markAsTouched(): void {
    this.onTouched();
  }

  public updateChanges() {
    this.changed.emit(this.value);
    this.onChange(this.value);
  }

  public writeValue(obj: any) {
    this.value = obj;
    this.updateChanges();
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
