import { IModel } from '@alberta/konexi-shared';
import { Inject, Injectable } from '@angular/core';
import { RawOptions } from 'src/app/common/contracts/repository/raw-options';
import { IUnitOfWork } from 'src/app/common/contracts/unit-of-work/unit-of-work';
import { IUnitOfWorkFactory } from 'src/app/common/contracts/unit-of-work/unit-of-work-factory';
import { Deferred } from 'src/app/common/deferred/deferred';
import { UnitOfWorkFactory } from 'src/app/common/unit-of-work/unit-of-work-factory';

import { Paginated } from '../contracts/database/paginated';
import { IQuery } from '../contracts/query/query';
import { IQuerySearchOptions } from '../contracts/query/query-search-options';
import { IQueryService } from '../contracts/query/query-service';

@Injectable({
  providedIn: 'root',
})
export class QueryService implements IQueryService {
  private unitOfWork: IUnitOfWork;
  private _ready: Deferred<void> = new Deferred();

  constructor(@Inject(UnitOfWorkFactory) unitOfWorkFactory: IUnitOfWorkFactory) {
    // tslint:disable-next-line: no-floating-promises
    (async () => {
      this.unitOfWork = await unitOfWorkFactory.create();
      this._ready.resolve();
    })();
  }

  public async get<T extends IModel>(
    id: string,
    database: string,
    options?: { useOnlineDatabase?: boolean }
  ): Promise<T> {
    await this._ready.promise;

    const useOnlineDatabase = options ? !!options.useOnlineDatabase : false;

    const repository = await this.unitOfWork.create<T>(useOnlineDatabase ? `${database}::online` : database);

    return repository.get(id);
  }

  public async getByIds<T extends IModel>(
    ids: string[],
    dbName: string,
    useOnlineDatabase: boolean = false
  ): Promise<T[]> {
    if (ids?.length && dbName) {
      await this._ready.promise;

      const repository = await this.unitOfWork.create<T>(useOnlineDatabase ? `${dbName}::online` : dbName);

      return repository.getItems(ids);
    }

    return [];
  }

  public async getAll<T extends IModel>(database: string): Promise<T[]> {
    await this._ready.promise;

    const repository = await this.unitOfWork.create<T>(database);

    return repository.getAll();
  }

  public async getPaginated<T extends IModel>(database: string, pageSize: number, offset: number): Promise<Paginated> {
    await this._ready.promise;

    const repository = await this.unitOfWork.create<T>(database);

    return repository.getPaginated(pageSize, offset);
  }

  public async search<T extends IModel>(query: IQuery, database: string, options?: IQuerySearchOptions): Promise<T[]> {
    await this._ready.promise;

    query.isIn = (options || { isIn: false }).isIn;
    if (typeof query.isIn === 'undefined') {
      query.isIn = false;
    }

    query.ignoreRegionIds = (options || { ignoreRegionIds: false }).ignoreRegionIds;
    query.ignoreMetadataType = (options || { ignoreMetadataType: false }).ignoreMetadataType;

    if (typeof query.ignoreRegionIds === 'undefined') {
      query.ignoreRegionIds = false;
    }
    if (typeof query.ignoreMetadataType === 'undefined') {
      query.ignoreMetadataType = false;
    }

    query.sort = options?.sort;

    const repository = await this.unitOfWork.create<T>(
      query.ignoreRegionIds ? `${database}::online` : database,
      !!query.ignoreMetadataType
    );
    return repository.search(query);
  }

  /**
   * @inheritdoc
   */
  public async raw<T extends IModel>(
    { sql, aggregation, query }: { sql?: string; aggregation?: any[]; query?: any },
    database: string,
    options?: RawOptions
  ): Promise<any> {
    sql = sql?.replace('{{FROM_TABLE}}', database.replace(/\.db$/, ''));
    const repository = await this.unitOfWork.create<T>(database);
    return repository.raw({ sql, aggregation, query, options });
  }

  async rawOnline<T extends IModel>(
    { aggregation, query }: { aggregation: any[]; query?: any },
    database: string,
    options?: RawOptions
  ): Promise<any> {
    const repository = await this.unitOfWork.create<T>(`${database}::online`);
    return repository.raw({ aggregation, query, options });
  }
}
