import { ArticleStatus, IArticle, IStock, PackagingUnitType } from '@alberta/konexi-shared';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ArticleViewModel } from 'src/app/business/article/view-models/article-view-model';
import { ArticleStatusPipe } from 'src/app/common/pipes/article/articlestatus-pipe';
import { ArticleStatusColorPipe } from 'src/app/common/pipes/article/articlestatuscolor-pipe';
import { InfoBoxType } from '../info-box/info-box.component';

@Component({
  selector: 'itl-integratedcare-article',
  templateUrl: './integratedcare-article.html',
  styleUrls: ['./integratedcare-article.scss'],
  providers: [DatePipe, ArticleStatusPipe],
})
export class IntegratedCareArticleComponent {
  public articleStatusUnknown = ArticleStatus.Unknown;
  public articleStatusAvailable = ArticleStatus.Available;
  public articleStatusDelayed = ArticleStatus.Delayed;
  public infoBoxTypeWarning = InfoBoxType.Warning;
  public infoBoxTypeInformation = InfoBoxType.Information;
  public packagingUnitTypePiece = PackagingUnitType.piece;

  @Input() article: ArticleViewModel;
  @Input() icon = '';
  @Input() iconColor: string;
  @Input() hasCheckbox = false;
  @Input() isChecked = false;
  @Input() maxAmountExceeded = false;
  @Input() showRatingStars = false;
  @Input() labelIsPrivateSale = false;
  @Input() labelNotAutomaticDelivery = false;
  @Input() labelStatus = false;
  @Input() isWizardPage = true;
  @Input() articleExpanded = false;
  @Input() hasAdditionalCards = false;
  @Input() hideChevron = false;
  @Input() articleIsDelayed: boolean;
  @Input() delayedTo: string;
  @Input() stock: IStock | null;
  @Output() articleExpandedChange = new EventEmitter();
  @Output() checked = new EventEmitter();

  constructor(
    private _translate: TranslateService,
    private _datePipe: DatePipe,
    private _articleStatusPipe: ArticleStatusPipe,
    private _articleStatusColorPipe: ArticleStatusColorPipe
  ) {}

  public getPackagingUnit(article: IArticle, id: string) {
    return article.packagingUnits?.find(packagingUnit => packagingUnit._id === id);
  }

  public getUnavailableFrom(unavailableFrom: Date) {
    if (!unavailableFrom) {
      return '';
    }
    return (
      this._translate.instant('article.edit.status.from') +
      ': ' +
      this._datePipe.transform(unavailableFrom, 'dd.MM.yyyy')
    );
  }

  public getUnavailableTo(unavailableTo: Date) {
    if (!unavailableTo) {
      return '';
    }
    return (
      this._translate.instant('article.edit.status.to') + ': ' + this._datePipe.transform(unavailableTo, 'dd.MM.yyyy')
    );
  }

  public getDelayedTo(delayedTo: Date) {
    if (!delayedTo) {
      return '';
    }
    return this._translate.instant('article.edit.status.to') + ': ' + this._datePipe.transform(delayedTo, 'dd.MM.yyyy');
  }

  getInfoHeader(article: IArticle) {
    if (!article) {
      return;
    }
    return (
      this._articleStatusPipe.transform(article.status) +
      ' ' +
      this.getUnavailableFrom(article.unavailableFrom) +
      ' ' +
      this.getUnavailableTo(article.unavailableTo) +
      ' ' +
      this.getDelayedTo(article.delayedTo)
    );
  }

  public toggleShowMore() {
    this.articleExpanded = !this.articleExpanded;
    this.articleExpandedChange.emit(this.articleExpanded);
  }

  public toggleCheckbox() {
    if (this.hasCheckbox) {
      this.checked.emit();
    }
  }

  public getBaseColor(article: IArticle) {
    if (!article) {
      return;
    }
    return this._articleStatusColorPipe.transform(article.status)?.trim();
  }

  public getStockBaseColor(stock: IStock) {
    if (stock.quantity < 1) {
      return '#CF0000';
    } else {
      return '#00998A';
    }
  }
}
